import clsx from "clsx";
import styles from "./Hero.module.scss";
import "./Hero.styles.css";
import { Button } from "../../../../shared/Button/Button";
import { useEffect, useState } from "react";
import { getRandomIntInclusive } from "../../../../utils/getRandomIntInclusive";
import FlashingStars from "../../../../shared/FlashingStars/FlashingStars";
// import { API_URL } from '../../../../utils'
import axios from "axios";
import { API_URL } from "../../../../utils";

export const Hero = () => {
  const [wiresInited, setWiresInited] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.get("verify") === "true") {
      window.location.replace("https://prophetbots.com/verify");
    }
  }, []);
  const [title, setTitle] = useState<string>(
    "The Telegram Trading Bot Prophecy"
  );
  const [text, setText] = useState<string>(
    // 'Experience The #1 Seamlessly Intelligent & Profit-Driven Telegram Bot Completely Free'
    ""
  );

  useEffect(() => {
    const sectionService = {
      async get() {
        const response = await axios.get(`${API_URL}/first-section`);

        return response.data;
      },
    };

    async function fetchData() {
      const response = await sectionService.get();

      if (response) {
        /* setTitle(response[0].title); */
        setText(response[0].text);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const wires = Array.from(
      document.querySelectorAll<HTMLDataElement>(".wire")
    );

    if (wires) {
      wires.forEach((wire) => {
        const delay = getRandomIntInclusive(0, 3);

        wire.style.animationDelay = `${delay}s`;

        setInterval(() => {
          if (!wire.classList.contains("anim")) {
            wire.classList.add("anim");

            if (wire) {
              wire.addEventListener("animationend", () => {
                wire.classList.remove("anim");

                const delay = getRandomIntInclusive(0, 3);

                wire.style.animationDelay = `${delay}s`;
              });
            }
          }
        }, 2000);
      });
    }
  }, []);

  return (
    <section id='hero' className={clsx(styles.hero, "section hero")}>
      <div className='container'>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            {window.innerWidth > 768 ? (
              <>
                <h1 className='reveal'>
                  {title &&
                    title
                      .split(" ")
                      .slice(0, 3)
                      .map((item) => `${item} `)}
                </h1>
                <h1 className='reveal'>
                  {title &&
                    title
                      .split(" ")
                      .slice(3)
                      .map((item) => `${item} `)}
                </h1>
              </>
            ) : (
              <>
                <h1 className='reveal'>The Telegram</h1>
                <h1 className='reveal'>Trading Bot </h1>
                <h1 className='reveal'>Prophecy</h1>
              </>
            )}
          </div>
          <div className={clsx("reveal", styles.tickerWrapper)}>
            <div className={styles.circle}></div>
            <div className={styles.ticker}>
              <div className={styles.string}>
                {" "}
                live now live now live now live now live now live now live now
                live now live now live now &nbsp;{" "}
              </div>{" "}
              <div className={styles.string}>
                {" "}
                live now live now live now live now live now live now live now
                live now live now live now &nbsp;{" "}
              </div>
              <div className={styles.bg}>
                <div className={styles.line}></div>
                <div className={styles.line}></div>
                <div className={styles.line}></div>
                <div className={styles.line}></div>
                <div className={styles.line}></div>
                <div className={styles.line}></div>
                <div className={styles.line}></div>
              </div>
            </div>
            <div className={styles.circle}></div>
          </div>
          <p className='reveal'>
            {text &&
              text.split(" ").map((item) => {
                if (item === "Telegram" || item === "Bot" || item === "Free") {
                  return (
                    <span className='white reveal'>
                      {`${item}`}
                      <div className='hidden-letter'>-</div>
                    </span>
                  );
                } else {
                  return `${item} `;
                }
              })}
          </p>
          <div className='hero-button-links'>
            <a href='https://t.me/officialprophetbot'>
              <Button
                className='reveal'
                icon='/images/icons/rocket.svg'
                colorType={"two"}
              >
                Start Trading
              </Button>
            </a>
            <a href='https://t.me/prophetverify'>
              <Button
                className='reveal'
                icon='/images/icons/eth.svg'
                colorType={"two"}
              >
                Token Signals
              </Button>
            </a>
          </div>
          <h6 className={clsx("reveal contract-header", styles.tickerWrapper)}>
            CA = 0xA9fbcC25435AD713a9468D8c89dd7baAe8914e3a
            <span>
              <div className='copy-clipboard-img'>
                <img
                  onClick={() => {
                    navigator.clipboard.writeText(
                      "0xA9fbcC25435AD713a9468D8c89dd7baAe8914e3a"
                    );
                  }}
                  className='copy-clipboard-img-test'
                  src='/images/iconmonstr-copy-lined.svg'
                  alt='logo'
                />
              </div>
            </span>
          </h6>
        </div>
      </div>
      <div className={styles.stars}>
        <FlashingStars withAnim={false} />
      </div>
      <div className={styles.scene}>
        <div className={styles.telegram}>
          <div>
            <img className='reveal' src='/images/Hero/telegram.png' alt='' />
          </div>
        </div>
        <div id='hero-light' className={styles.light}>
          <img src='/images/Hero/light.svg' alt='' />
        </div>
        <div className={clsx(styles.platform, "reveal")}>
          <img src='/images/Hero/platform.svg' alt='' />
        </div>
      </div>
      <div className={styles.grid}>
        <div className='reveal'>
          <svg
            width={
              window.innerWidth <= 768
                ? window.innerWidth * 2
                : window.innerWidth >= 1920
                ? `1920`
                : window.innerWidth
            }
            height={window.innerHeight >= 1080 ? `1080` : window.innerHeight}
            viewBox={`0 0 ${
              window.innerWidth <= 768
                ? window.innerWidth * 2
                : window.innerWidth >= 1920
                ? `1920`
                : window.innerWidth
            } ${window.innerHeight >= 1080 ? `1080` : window.innerHeight}`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clip-path='url(#clip0_528_3282)'>
              <path
                d='M134.101 2.87802C134.717 2.52334 135.475 2.52237 136.092 2.87547L263.998 76.0725C265.331 76.8357 265.342 78.7552 264.017 79.5331L136.111 154.616C135.484 154.984 134.707 154.983 134.082 154.614L6.93033 79.5304C5.61114 78.7514 5.62173 76.8393 6.94945 76.0749L134.101 2.87802Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M395.076 2.87802C395.692 2.52334 396.45 2.52237 397.067 2.87547L524.973 76.0725C526.307 76.8357 526.317 78.7552 524.992 79.5331L397.086 154.616C396.459 154.984 395.682 154.983 395.057 154.614L267.905 79.5304C266.586 78.7514 266.597 76.8393 267.924 76.0749L395.076 2.87802Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M656.05 2.87802C656.666 2.52334 657.424 2.52237 658.041 2.87547L785.948 76.0725C787.281 76.8357 787.292 78.7552 785.967 79.5331L658.061 154.616C657.434 154.984 656.657 154.983 656.031 154.614L528.88 79.5304C527.561 78.7514 527.571 76.8393 528.899 76.0749L656.05 2.87802Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M917.025 2.87802C917.641 2.52334 918.4 2.52237 919.017 2.87547L1046.92 76.0725C1048.26 76.8357 1048.27 78.7552 1046.94 79.5331L919.036 154.616C918.409 154.984 917.632 154.983 917.006 154.614L789.855 79.5304C788.536 78.7514 788.546 76.8393 789.874 76.0749L917.025 2.87802Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1178 2.87802C1178.62 2.52334 1179.37 2.52237 1179.99 2.87547L1307.9 76.0725C1309.23 76.8357 1309.24 78.7552 1307.92 79.5331L1180.01 154.616C1179.38 154.984 1178.61 154.983 1177.98 154.614L1050.83 79.5304C1049.51 78.7514 1049.52 76.8393 1050.85 76.0749L1178 2.87802Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1438.98 2.87802C1439.59 2.52334 1440.35 2.52237 1440.97 2.87547L1568.87 76.0725C1570.21 76.8357 1570.22 78.7552 1568.89 79.5331L1440.99 154.616C1440.36 154.984 1439.58 154.983 1438.96 154.614L1311.81 79.5304C1310.49 78.7514 1310.5 76.8393 1311.82 76.0749L1438.98 2.87802Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1699.95 2.87802C1700.57 2.52334 1701.32 2.52237 1701.94 2.87547L1829.85 76.0725C1831.18 76.8357 1831.19 78.7552 1829.87 79.5331L1701.96 154.616C1701.33 154.984 1700.56 154.983 1699.93 154.614L1572.78 79.5304C1571.46 78.7514 1571.47 76.8393 1572.8 76.0749L1699.95 2.87802Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M134.101 153.413C134.717 153.058 135.475 153.057 136.092 153.41L263.998 226.607C265.331 227.371 265.342 229.29 264.017 230.068L136.111 305.151C135.484 305.519 134.707 305.518 134.082 305.149L6.93033 230.065C5.61114 229.286 5.62173 227.374 6.94945 226.61L134.101 153.413Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M395.076 153.413C395.692 153.058 396.45 153.057 397.067 153.41L524.973 226.607C526.307 227.371 526.317 229.29 524.992 230.068L397.086 305.151C396.459 305.519 395.682 305.518 395.057 305.149L267.905 230.065C266.586 229.286 266.597 227.374 267.924 226.61L395.076 153.413Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M656.05 153.413C656.666 153.058 657.424 153.057 658.041 153.41L785.948 226.607C787.281 227.371 787.292 229.29 785.967 230.068L658.061 305.151C657.434 305.519 656.657 305.518 656.031 305.149L528.88 230.065C527.561 229.286 527.571 227.374 528.899 226.61L656.05 153.413Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M917.025 153.413C917.641 153.058 918.4 153.057 919.017 153.41L1046.92 226.607C1048.26 227.371 1048.27 229.29 1046.94 230.068L919.036 305.151C918.409 305.519 917.632 305.518 917.006 305.149L789.855 230.065C788.536 229.286 788.546 227.374 789.874 226.61L917.025 153.413Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1178 153.413C1178.62 153.058 1179.37 153.057 1179.99 153.41L1307.9 226.607C1309.23 227.371 1309.24 229.29 1307.92 230.068L1180.01 305.151C1179.38 305.519 1178.61 305.518 1177.98 305.149L1050.83 230.065C1049.51 229.286 1049.52 227.374 1050.85 226.61L1178 153.413Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1438.98 153.413C1439.59 153.058 1440.35 153.057 1440.97 153.41L1568.87 226.607C1570.21 227.371 1570.22 229.29 1568.89 230.068L1440.99 305.151C1440.36 305.519 1439.58 305.518 1438.96 305.149L1311.81 230.065C1310.49 229.286 1310.5 227.374 1311.82 226.61L1438.98 153.413Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1699.95 153.413C1700.57 153.058 1701.32 153.057 1701.94 153.41L1829.85 226.607C1831.18 227.371 1831.19 229.29 1829.87 230.068L1701.96 305.151C1701.33 305.519 1700.56 305.518 1699.93 305.149L1572.78 230.065C1571.46 229.286 1571.47 227.374 1572.8 226.61L1699.95 153.413Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M134.101 303.947C134.717 303.592 135.475 303.591 136.092 303.945L263.998 377.142C265.331 377.905 265.342 379.824 264.017 380.602L136.111 455.686C135.484 456.053 134.707 456.052 134.082 455.683L6.93033 380.599C5.61114 379.82 5.62173 377.908 6.94945 377.144L134.101 303.947Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M395.076 303.947C395.692 303.592 396.45 303.591 397.067 303.945L524.973 377.142C526.307 377.905 526.317 379.824 524.992 380.602L397.086 455.686C396.459 456.053 395.682 456.052 395.057 455.683L267.905 380.599C266.586 379.82 266.597 377.908 267.924 377.144L395.076 303.947Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M656.05 303.947C656.666 303.592 657.424 303.591 658.041 303.945L785.948 377.142C787.281 377.905 787.292 379.824 785.967 380.602L658.061 455.686C657.434 456.053 656.657 456.052 656.031 455.683L528.88 380.599C527.561 379.82 527.571 377.908 528.899 377.144L656.05 303.947Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M917.025 303.947C917.641 303.592 918.4 303.591 919.017 303.945L1046.92 377.142C1048.26 377.905 1048.27 379.824 1046.94 380.602L919.036 455.686C918.409 456.053 917.632 456.052 917.006 455.683L789.855 380.599C788.536 379.82 788.546 377.908 789.874 377.144L917.025 303.947Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1178 303.947C1178.62 303.592 1179.37 303.591 1179.99 303.945L1307.9 377.142C1309.23 377.905 1309.24 379.824 1307.92 380.602L1180.01 455.686C1179.38 456.053 1178.61 456.052 1177.98 455.683L1050.83 380.599C1049.51 379.82 1049.52 377.908 1050.85 377.144L1178 303.947Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1438.98 303.947C1439.59 303.592 1440.35 303.591 1440.97 303.945L1568.87 377.142C1570.21 377.905 1570.22 379.824 1568.89 380.602L1440.99 455.686C1440.36 456.053 1439.58 456.052 1438.96 455.683L1311.81 380.599C1310.49 379.82 1310.5 377.908 1311.82 377.144L1438.98 303.947Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1699.95 303.947C1700.57 303.592 1701.32 303.591 1701.94 303.945L1829.85 377.142C1831.18 377.905 1831.19 379.824 1829.87 380.602L1701.96 455.686C1701.33 456.053 1700.56 456.052 1699.93 455.683L1572.78 380.599C1571.46 379.82 1571.47 377.908 1572.8 377.144L1699.95 303.947Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M134.101 454.481C134.717 454.127 135.475 454.126 136.092 454.479L263.998 527.676C265.331 528.439 265.342 530.358 264.017 531.136L136.111 606.22C135.484 606.588 134.707 606.587 134.082 606.217L6.93033 531.134C5.61114 530.355 5.62173 528.443 6.94945 527.678L134.101 454.481Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M395.076 454.481C395.692 454.127 396.45 454.126 397.067 454.479L524.973 527.676C526.307 528.439 526.317 530.358 524.992 531.136L397.086 606.22C396.459 606.588 395.682 606.587 395.057 606.217L267.905 531.134C266.586 530.355 266.597 528.443 267.924 527.678L395.076 454.481Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M656.05 454.481C656.666 454.127 657.424 454.126 658.041 454.479L785.948 527.676C787.281 528.439 787.292 530.358 785.967 531.136L658.061 606.22C657.434 606.588 656.657 606.587 656.031 606.217L528.88 531.134C527.561 530.355 527.571 528.443 528.899 527.678L656.05 454.481Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M917.025 454.481C917.641 454.127 918.4 454.126 919.017 454.479L1046.92 527.676C1048.26 528.439 1048.27 530.358 1046.94 531.136L919.036 606.22C918.409 606.588 917.632 606.587 917.006 606.217L789.855 531.134C788.536 530.355 788.546 528.443 789.874 527.678L917.025 454.481Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1178 454.481C1178.62 454.127 1179.37 454.126 1179.99 454.479L1307.9 527.676C1309.23 528.439 1309.24 530.358 1307.92 531.136L1180.01 606.22C1179.38 606.588 1178.61 606.587 1177.98 606.217L1050.83 531.134C1049.51 530.355 1049.52 528.443 1050.85 527.678L1178 454.481Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1438.98 454.481C1439.59 454.127 1440.35 454.126 1440.97 454.479L1568.87 527.676C1570.21 528.439 1570.22 530.358 1568.89 531.136L1440.99 606.22C1440.36 606.588 1439.58 606.587 1438.96 606.217L1311.81 531.134C1310.49 530.355 1310.5 528.443 1311.82 527.678L1438.98 454.481Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1699.95 454.481C1700.57 454.127 1701.32 454.126 1701.94 454.479L1829.85 527.676C1831.18 528.439 1831.19 530.358 1829.87 531.136L1701.96 606.22C1701.33 606.588 1700.56 606.587 1699.93 606.217L1572.78 531.134C1571.46 530.355 1571.47 528.443 1572.8 527.678L1699.95 454.481Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M134.101 605.014C134.717 604.66 135.475 604.659 136.092 605.012L263.998 678.209C265.331 678.972 265.342 680.892 264.017 681.67L136.111 756.753C135.484 757.121 134.707 757.12 134.082 756.75L6.93033 681.667C5.61114 680.888 5.62173 678.976 6.94945 678.211L134.101 605.014Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M395.076 605.014C395.692 604.66 396.45 604.659 397.067 605.012L524.973 678.209C526.307 678.972 526.317 680.892 524.992 681.67L397.086 756.753C396.459 757.121 395.682 757.12 395.057 756.75L267.905 681.667C266.586 680.888 266.597 678.976 267.924 678.211L395.076 605.014Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M656.05 605.014C656.666 604.66 657.424 604.659 658.041 605.012L785.948 678.209C787.281 678.972 787.292 680.892 785.967 681.67L658.061 756.753C657.434 757.121 656.657 757.12 656.031 756.75L528.88 681.667C527.561 680.888 527.571 678.976 528.899 678.211L656.05 605.014Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M917.025 605.014C917.641 604.66 918.4 604.659 919.017 605.012L1046.92 678.209C1048.26 678.972 1048.27 680.892 1046.94 681.67L919.036 756.753C918.409 757.121 917.632 757.12 917.006 756.75L789.855 681.667C788.536 680.888 788.546 678.976 789.874 678.211L917.025 605.014Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1178 605.014C1178.62 604.66 1179.37 604.659 1179.99 605.012L1307.9 678.209C1309.23 678.972 1309.24 680.892 1307.92 681.67L1180.01 756.753C1179.38 757.121 1178.61 757.12 1177.98 756.75L1050.83 681.667C1049.51 680.888 1049.52 678.976 1050.85 678.211L1178 605.014Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1438.98 605.014C1439.59 604.66 1440.35 604.659 1440.97 605.012L1568.87 678.209C1570.21 678.972 1570.22 680.892 1568.89 681.67L1440.99 756.753C1440.36 757.121 1439.58 757.12 1438.96 756.75L1311.81 681.667C1310.49 680.888 1310.5 678.976 1311.82 678.211L1438.98 605.014Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1699.95 605.014C1700.57 604.66 1701.32 604.659 1701.94 605.012L1829.85 678.209C1831.18 678.972 1831.19 680.892 1829.87 681.67L1701.96 756.753C1701.33 757.121 1700.56 757.12 1699.93 756.75L1572.78 681.667C1571.46 680.888 1571.47 678.976 1572.8 678.211L1699.95 605.014Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M134.101 755.55C134.717 755.195 135.475 755.194 136.092 755.548L263.998 828.745C265.331 829.508 265.342 831.427 264.017 832.205L136.111 907.289C135.484 907.657 134.707 907.656 134.082 907.286L6.93033 832.203C5.61114 831.424 5.62173 829.511 6.94945 828.747L134.101 755.55Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M395.076 755.55C395.692 755.195 396.45 755.194 397.067 755.548L524.973 828.745C526.307 829.508 526.317 831.427 524.992 832.205L397.086 907.289C396.459 907.657 395.682 907.656 395.057 907.286L267.905 832.203C266.586 831.424 266.597 829.511 267.924 828.747L395.076 755.55Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M656.05 755.55C656.666 755.195 657.424 755.194 658.041 755.548L785.948 828.745C787.281 829.508 787.292 831.427 785.967 832.205L658.061 907.289C657.434 907.657 656.657 907.656 656.031 907.286L528.88 832.203C527.561 831.424 527.571 829.511 528.899 828.747L656.05 755.55Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M917.025 755.55C917.641 755.195 918.4 755.194 919.017 755.548L1046.92 828.745C1048.26 829.508 1048.27 831.427 1046.94 832.205L919.036 907.289C918.409 907.657 917.632 907.656 917.006 907.286L789.855 832.203C788.536 831.424 788.546 829.511 789.874 828.747L917.025 755.55Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1178 755.55C1178.62 755.195 1179.37 755.194 1179.99 755.548L1307.9 828.745C1309.23 829.508 1309.24 831.427 1307.92 832.205L1180.01 907.289C1179.38 907.657 1178.61 907.656 1177.98 907.286L1050.83 832.203C1049.51 831.424 1049.52 829.511 1050.85 828.747L1178 755.55Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1438.98 755.55C1439.59 755.195 1440.35 755.194 1440.97 755.548L1568.87 828.745C1570.21 829.508 1570.22 831.427 1568.89 832.205L1440.99 907.289C1440.36 907.657 1439.58 907.656 1438.96 907.286L1311.81 832.203C1310.49 831.424 1310.5 829.511 1311.82 828.747L1438.98 755.55Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1699.95 755.55C1700.57 755.195 1701.32 755.194 1701.94 755.548L1829.85 828.745C1831.18 829.508 1831.19 831.427 1829.87 832.205L1701.96 907.289C1701.33 907.657 1700.56 907.656 1699.93 907.286L1572.78 832.203C1571.46 831.424 1571.47 829.511 1572.8 828.747L1699.95 755.55Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1439.01 608L1576.01 528.476L1439.01 453.714L1576.01 378'
                stroke='url(#paint0_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M395.006 606.604L532.006 527.079L395.006 452.318L532.006 376.604'
                stroke='url(#paint1_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M656 607L793 527.476L656 452.714L793 377'
                stroke='url(#paint2_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M917 607L1054 527.476L917 452.714L1054 377'
                stroke='url(#paint3_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1176 608L1313 528.476L1176 453.714L1313 378'
                stroke='url(#paint4_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1702 608L1839 528.476L1702 453.714L1839 378'
                stroke='url(#paint5_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M130 607L267 527.476L130 452.714L267 377'
                stroke='url(#paint6_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1439 306L1570 227.513L1439 153.727L1570 79'
                stroke='url(#paint7_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1702 306L1833 227.513L1702 153.727L1833 79'
                stroke='url(#paint8_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1570 531L1701 452.513L1570 378.727L1701 304'
                stroke='url(#paint9_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1180 307L1313 227.476L1180 152.714L1313 77'
                stroke='url(#paint10_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M921 306L1050 226.476L921 151.714L1050 76'
                stroke='url(#paint11_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M658 306L788 226.822L658 152.385L788 77'
                stroke='url(#paint12_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M397 306L528 227.168L397 153.056L528 78'
                stroke='url(#paint13_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M137 306L267 227.168L137 153.056L267 78'
                stroke='url(#paint14_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1303.01 1136.4L1440.7 1058.07L1304.35 982.117L1442.01 907.602'
                stroke='url(#paint15_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M259.005 1135L396.693 1056.68L260.351 980.721L398.006 906.205'
                stroke='url(#paint16_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M519.999 1135.4L657.688 1057.07L521.345 981.117L659.001 906.602'
                stroke='url(#paint17_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M780.999 1135.4L918.688 1057.07L782.345 981.117L920.001 906.602'
                stroke='url(#paint18_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1040 1136.4L1177.69 1058.07L1041.35 982.117L1179 907.602'
                stroke='url(#paint19_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1566 1136.4L1703.69 1058.07L1567.35 982.117L1705 907.602'
                stroke='url(#paint20_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M-6.00222 1135.4L131.687 1057.07L-4.65584 981.117L133 906.602'
                stroke='url(#paint21_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1303.01 834.423L1434.69 757.082L1304.34 682.156L1435.99 608.575'
                stroke='url(#paint22_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1044 835.415L1177.69 757.055L1045.35 681.135L1179 606.584'
                stroke='url(#paint23_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M784.999 834.431L914.688 756.035L786.345 680.151L916.001 605.565'
                stroke='url(#paint24_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M522.003 834.429L652.689 756.388L523.343 680.82L653.996 606.572'
                stroke='url(#paint25_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M261.008 834.423L392.691 756.737L262.343 681.485L393.993 607.575'
                stroke='url(#paint26_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1.0084 834.429L131.691 756.734L2.34307 681.491L132.993 607.572'
                stroke='url(#paint27_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1303.01 834.423L1434.69 757.082L1304.34 682.156L1435.99 608.575'
                stroke='url(#paint28_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1044 835.415L1177.69 757.055L1045.35 681.135L1179 606.584'
                stroke='url(#paint29_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M784.999 834.431L914.688 756.035L786.345 680.151L916.001 605.565'
                stroke='url(#paint30_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M522.003 834.429L652.689 756.388L523.343 680.82L653.996 606.572'
                stroke='url(#paint31_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M261.008 834.423L392.691 756.737L262.343 681.485L393.993 607.575'
                stroke='url(#paint32_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1.0084 834.429L131.691 756.734L2.34307 681.491L132.993 607.572'
                stroke='url(#paint33_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1568 833.991L1701.69 755.631L1569.35 679.711L1703 605.161'
                stroke='url(#paint34_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1700 910.991L1833.69 832.631L1701.35 756.711L1835 682.161'
                stroke='url(#paint35_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
            </g>
            <g clip-path='url(#clip1_528_3282)'>
              <path
                d='M135.101 907.878C135.717 907.523 136.475 907.522 137.092 907.875L264.998 981.072C266.331 981.836 266.342 983.755 265.017 984.533L137.111 1059.62C136.484 1059.98 135.707 1059.98 135.082 1059.61L7.93033 984.53C6.61114 983.751 6.62173 981.839 7.94945 981.075L135.101 907.878Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M396.076 907.878C396.692 907.523 397.45 907.522 398.067 907.875L525.973 981.072C527.307 981.836 527.317 983.755 525.992 984.533L398.086 1059.62C397.459 1059.98 396.682 1059.98 396.057 1059.61L268.905 984.53C267.586 983.751 267.597 981.839 268.924 981.075L396.076 907.878Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M657.05 907.878C657.666 907.523 658.424 907.522 659.041 907.875L786.948 981.072C788.281 981.836 788.292 983.755 786.967 984.533L659.061 1059.62C658.434 1059.98 657.657 1059.98 657.031 1059.61L529.88 984.53C528.561 983.751 528.571 981.839 529.899 981.075L657.05 907.878Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M918.025 907.878C918.641 907.523 919.4 907.522 920.017 907.875L1047.92 981.072C1049.26 981.836 1049.27 983.755 1047.94 984.533L920.036 1059.62C919.409 1059.98 918.632 1059.98 918.006 1059.61L790.855 984.53C789.536 983.751 789.546 981.839 790.874 981.075L918.025 907.878Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1179 907.878C1179.62 907.523 1180.37 907.522 1180.99 907.875L1308.9 981.072C1310.23 981.836 1310.24 983.755 1308.92 984.533L1181.01 1059.62C1180.38 1059.98 1179.61 1059.98 1178.98 1059.61L1051.83 984.53C1050.51 983.751 1050.52 981.839 1051.85 981.075L1179 907.878Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1439.98 907.878C1440.59 907.523 1441.35 907.522 1441.97 907.875L1569.87 981.072C1571.21 981.836 1571.22 983.755 1569.89 984.533L1441.99 1059.62C1441.36 1059.98 1440.58 1059.98 1439.96 1059.61L1312.81 984.53C1311.49 983.751 1311.5 981.839 1312.82 981.075L1439.98 907.878Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1700.95 907.878C1701.57 907.523 1702.32 907.522 1702.94 907.875L1830.85 981.072C1832.18 981.836 1832.19 983.755 1830.87 984.533L1702.96 1059.62C1702.33 1059.98 1701.56 1059.98 1700.93 1059.61L1573.78 984.53C1572.46 983.751 1572.47 981.839 1573.8 981.075L1700.95 907.878Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M135.101 1058.41C135.717 1058.06 136.475 1058.06 137.092 1058.41L264.998 1131.61C266.331 1132.37 266.342 1134.29 265.017 1135.07L137.111 1210.15C136.484 1210.52 135.707 1210.52 135.082 1210.15L7.93033 1135.07C6.61114 1134.29 6.62173 1132.37 7.94945 1131.61L135.101 1058.41Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M396.076 1058.41C396.692 1058.06 397.45 1058.06 398.067 1058.41L525.973 1131.61C527.307 1132.37 527.317 1134.29 525.992 1135.07L398.086 1210.15C397.459 1210.52 396.682 1210.52 396.057 1210.15L268.905 1135.07C267.586 1134.29 267.597 1132.37 268.924 1131.61L396.076 1058.41Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M657.05 1058.41C657.666 1058.06 658.424 1058.06 659.041 1058.41L786.948 1131.61C788.281 1132.37 788.292 1134.29 786.967 1135.07L659.061 1210.15C658.434 1210.52 657.657 1210.52 657.031 1210.15L529.88 1135.07C528.561 1134.29 528.571 1132.37 529.899 1131.61L657.05 1058.41Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M918.025 1058.41C918.641 1058.06 919.4 1058.06 920.017 1058.41L1047.92 1131.61C1049.26 1132.37 1049.27 1134.29 1047.94 1135.07L920.036 1210.15C919.409 1210.52 918.632 1210.52 918.006 1210.15L790.855 1135.07C789.536 1134.29 789.546 1132.37 790.874 1131.61L918.025 1058.41Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1179 1058.41C1179.62 1058.06 1180.37 1058.06 1180.99 1058.41L1308.9 1131.61C1310.23 1132.37 1310.24 1134.29 1308.92 1135.07L1181.01 1210.15C1180.38 1210.52 1179.61 1210.52 1178.98 1210.15L1051.83 1135.07C1050.51 1134.29 1050.52 1132.37 1051.85 1131.61L1179 1058.41Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1439.98 1058.41C1440.59 1058.06 1441.35 1058.06 1441.97 1058.41L1569.87 1131.61C1571.21 1132.37 1571.22 1134.29 1569.89 1135.07L1441.99 1210.15C1441.36 1210.52 1440.58 1210.52 1439.96 1210.15L1312.81 1135.07C1311.49 1134.29 1311.5 1132.37 1312.82 1131.61L1439.98 1058.41Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1700.95 1058.41C1701.57 1058.06 1702.32 1058.06 1702.94 1058.41L1830.85 1131.61C1832.18 1132.37 1832.19 1134.29 1830.87 1135.07L1702.96 1210.15C1702.33 1210.52 1701.56 1210.52 1700.93 1210.15L1573.78 1135.07C1572.46 1134.29 1572.47 1132.37 1573.8 1131.61L1700.95 1058.41Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M135.101 1208.95C135.717 1208.59 136.475 1208.59 137.092 1208.94L264.998 1282.14C266.331 1282.9 266.342 1284.82 265.017 1285.6L137.111 1360.69C136.484 1361.05 135.707 1361.05 135.082 1360.68L7.93033 1285.6C6.61114 1284.82 6.62173 1282.91 7.94945 1282.14L135.101 1208.95Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M396.076 1208.95C396.692 1208.59 397.45 1208.59 398.067 1208.94L525.973 1282.14C527.307 1282.9 527.317 1284.82 525.992 1285.6L398.086 1360.69C397.459 1361.05 396.682 1361.05 396.057 1360.68L268.905 1285.6C267.586 1284.82 267.597 1282.91 268.924 1282.14L396.076 1208.95Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M657.05 1208.95C657.666 1208.59 658.424 1208.59 659.041 1208.94L786.948 1282.14C788.281 1282.9 788.292 1284.82 786.967 1285.6L659.061 1360.69C658.434 1361.05 657.657 1361.05 657.031 1360.68L529.88 1285.6C528.561 1284.82 528.571 1282.91 529.899 1282.14L657.05 1208.95Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M918.025 1208.95C918.641 1208.59 919.4 1208.59 920.017 1208.94L1047.92 1282.14C1049.26 1282.9 1049.27 1284.82 1047.94 1285.6L920.036 1360.69C919.409 1361.05 918.632 1361.05 918.006 1360.68L790.855 1285.6C789.536 1284.82 789.546 1282.91 790.874 1282.14L918.025 1208.95Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1179 1208.95C1179.62 1208.59 1180.37 1208.59 1180.99 1208.94L1308.9 1282.14C1310.23 1282.9 1310.24 1284.82 1308.92 1285.6L1181.01 1360.69C1180.38 1361.05 1179.61 1361.05 1178.98 1360.68L1051.83 1285.6C1050.51 1284.82 1050.52 1282.91 1051.85 1282.14L1179 1208.95Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1439.98 1208.95C1440.59 1208.59 1441.35 1208.59 1441.97 1208.94L1569.87 1282.14C1571.21 1282.9 1571.22 1284.82 1569.89 1285.6L1441.99 1360.69C1441.36 1361.05 1440.58 1361.05 1439.96 1360.68L1312.81 1285.6C1311.49 1284.82 1311.5 1282.91 1312.82 1282.14L1439.98 1208.95Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1700.95 1208.95C1701.57 1208.59 1702.32 1208.59 1702.94 1208.94L1830.85 1282.14C1832.18 1282.9 1832.19 1284.82 1830.87 1285.6L1702.96 1360.69C1702.33 1361.05 1701.56 1361.05 1700.93 1360.68L1573.78 1285.6C1572.46 1284.82 1572.47 1282.91 1573.8 1282.14L1700.95 1208.95Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M135.101 1359.48C135.717 1359.13 136.475 1359.13 137.092 1359.48L264.998 1432.68C266.331 1433.44 266.342 1435.36 265.017 1436.14L137.111 1511.22C136.484 1511.59 135.707 1511.59 135.082 1511.22L7.93033 1436.13C6.61114 1435.35 6.62173 1433.44 7.94945 1432.68L135.101 1359.48Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M396.076 1359.48C396.692 1359.13 397.45 1359.13 398.067 1359.48L525.973 1432.68C527.307 1433.44 527.317 1435.36 525.992 1436.14L398.086 1511.22C397.459 1511.59 396.682 1511.59 396.057 1511.22L268.905 1436.13C267.586 1435.35 267.597 1433.44 268.924 1432.68L396.076 1359.48Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M657.05 1359.48C657.666 1359.13 658.424 1359.13 659.041 1359.48L786.948 1432.68C788.281 1433.44 788.292 1435.36 786.967 1436.14L659.061 1511.22C658.434 1511.59 657.657 1511.59 657.031 1511.22L529.88 1436.13C528.561 1435.35 528.571 1433.44 529.899 1432.68L657.05 1359.48Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M918.025 1359.48C918.641 1359.13 919.4 1359.13 920.017 1359.48L1047.92 1432.68C1049.26 1433.44 1049.27 1435.36 1047.94 1436.14L920.036 1511.22C919.409 1511.59 918.632 1511.59 918.006 1511.22L790.855 1436.13C789.536 1435.35 789.546 1433.44 790.874 1432.68L918.025 1359.48Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1179 1359.48C1179.62 1359.13 1180.37 1359.13 1180.99 1359.48L1308.9 1432.68C1310.23 1433.44 1310.24 1435.36 1308.92 1436.14L1181.01 1511.22C1180.38 1511.59 1179.61 1511.59 1178.98 1511.22L1051.83 1436.13C1050.51 1435.35 1050.52 1433.44 1051.85 1432.68L1179 1359.48Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1439.98 1359.48C1440.59 1359.13 1441.35 1359.13 1441.97 1359.48L1569.87 1432.68C1571.21 1433.44 1571.22 1435.36 1569.89 1436.14L1441.99 1511.22C1441.36 1511.59 1440.58 1511.59 1439.96 1511.22L1312.81 1436.13C1311.49 1435.35 1311.5 1433.44 1312.82 1432.68L1439.98 1359.48Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1700.95 1359.48C1701.57 1359.13 1702.32 1359.13 1702.94 1359.48L1830.85 1432.68C1832.18 1433.44 1832.19 1435.36 1830.87 1436.14L1702.96 1511.22C1702.33 1511.59 1701.56 1511.59 1700.93 1511.22L1573.78 1436.13C1572.46 1435.35 1572.47 1433.44 1573.8 1432.68L1700.95 1359.48Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M135.101 1510.01C135.717 1509.66 136.475 1509.66 137.092 1510.01L264.998 1583.21C266.331 1583.97 266.342 1585.89 265.017 1586.67L137.111 1661.75C136.484 1662.12 135.707 1662.12 135.082 1661.75L7.93033 1586.67C6.61114 1585.89 6.62173 1583.98 7.94945 1583.21L135.101 1510.01Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M396.076 1510.01C396.692 1509.66 397.45 1509.66 398.067 1510.01L525.973 1583.21C527.307 1583.97 527.317 1585.89 525.992 1586.67L398.086 1661.75C397.459 1662.12 396.682 1662.12 396.057 1661.75L268.905 1586.67C267.586 1585.89 267.597 1583.98 268.924 1583.21L396.076 1510.01Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M657.05 1510.01C657.666 1509.66 658.424 1509.66 659.041 1510.01L786.948 1583.21C788.281 1583.97 788.292 1585.89 786.967 1586.67L659.061 1661.75C658.434 1662.12 657.657 1662.12 657.031 1661.75L529.88 1586.67C528.561 1585.89 528.571 1583.98 529.899 1583.21L657.05 1510.01Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M918.025 1510.01C918.641 1509.66 919.4 1509.66 920.017 1510.01L1047.92 1583.21C1049.26 1583.97 1049.27 1585.89 1047.94 1586.67L920.036 1661.75C919.409 1662.12 918.632 1662.12 918.006 1661.75L790.855 1586.67C789.536 1585.89 789.546 1583.98 790.874 1583.21L918.025 1510.01Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1179 1510.01C1179.62 1509.66 1180.37 1509.66 1180.99 1510.01L1308.9 1583.21C1310.23 1583.97 1310.24 1585.89 1308.92 1586.67L1181.01 1661.75C1180.38 1662.12 1179.61 1662.12 1178.98 1661.75L1051.83 1586.67C1050.51 1585.89 1050.52 1583.98 1051.85 1583.21L1179 1510.01Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1439.98 1510.01C1440.59 1509.66 1441.35 1509.66 1441.97 1510.01L1569.87 1583.21C1571.21 1583.97 1571.22 1585.89 1569.89 1586.67L1441.99 1661.75C1441.36 1662.12 1440.58 1662.12 1439.96 1661.75L1312.81 1586.67C1311.49 1585.89 1311.5 1583.98 1312.82 1583.21L1439.98 1510.01Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1700.95 1510.01C1701.57 1509.66 1702.32 1509.66 1702.94 1510.01L1830.85 1583.21C1832.18 1583.97 1832.19 1585.89 1830.87 1586.67L1702.96 1661.75C1702.33 1662.12 1701.56 1662.12 1700.93 1661.75L1573.78 1586.67C1572.46 1585.89 1572.47 1583.98 1573.8 1583.21L1700.95 1510.01Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M135.101 1660.55C135.717 1660.2 136.475 1660.19 137.092 1660.55L264.998 1733.75C266.331 1734.51 266.342 1736.43 265.017 1737.21L137.111 1812.29C136.484 1812.66 135.707 1812.66 135.082 1812.29L7.93033 1737.2C6.61114 1736.42 6.62173 1734.51 7.94945 1733.75L135.101 1660.55Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M396.076 1660.55C396.692 1660.2 397.45 1660.19 398.067 1660.55L525.973 1733.75C527.307 1734.51 527.317 1736.43 525.992 1737.21L398.086 1812.29C397.459 1812.66 396.682 1812.66 396.057 1812.29L268.905 1737.2C267.586 1736.42 267.597 1734.51 268.924 1733.75L396.076 1660.55Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M657.05 1660.55C657.666 1660.2 658.424 1660.19 659.041 1660.55L786.948 1733.75C788.281 1734.51 788.292 1736.43 786.967 1737.21L659.061 1812.29C658.434 1812.66 657.657 1812.66 657.031 1812.29L529.88 1737.2C528.561 1736.42 528.571 1734.51 529.899 1733.75L657.05 1660.55Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M918.025 1660.55C918.641 1660.2 919.4 1660.19 920.017 1660.55L1047.92 1733.75C1049.26 1734.51 1049.27 1736.43 1047.94 1737.21L920.036 1812.29C919.409 1812.66 918.632 1812.66 918.006 1812.29L790.855 1737.2C789.536 1736.42 789.546 1734.51 790.874 1733.75L918.025 1660.55Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1179 1660.55C1179.62 1660.2 1180.37 1660.19 1180.99 1660.55L1308.9 1733.75C1310.23 1734.51 1310.24 1736.43 1308.92 1737.21L1181.01 1812.29C1180.38 1812.66 1179.61 1812.66 1178.98 1812.29L1051.83 1737.2C1050.51 1736.42 1050.52 1734.51 1051.85 1733.75L1179 1660.55Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1439.98 1660.55C1440.59 1660.2 1441.35 1660.19 1441.97 1660.55L1569.87 1733.75C1571.21 1734.51 1571.22 1736.43 1569.89 1737.21L1441.99 1812.29C1441.36 1812.66 1440.58 1812.66 1439.96 1812.29L1312.81 1737.2C1311.49 1736.42 1311.5 1734.51 1312.82 1733.75L1439.98 1660.55Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1700.95 1660.55C1701.57 1660.2 1702.32 1660.19 1702.94 1660.55L1830.85 1733.75C1832.18 1734.51 1832.19 1736.43 1830.87 1737.21L1702.96 1812.29C1702.33 1812.66 1701.56 1812.66 1700.93 1812.29L1573.78 1737.2C1572.46 1736.42 1572.47 1734.51 1573.8 1733.75L1700.95 1660.55Z'
                stroke='#26033B'
                stroke-width='4'
              />
              <path
                d='M1308.01 1588L1445.01 1508.48L1308.01 1433.71L1445.01 1358'
                stroke='url(#paint36_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M264.006 1586.6L401.006 1507.08L264.006 1432.32L401.006 1356.6'
                stroke='url(#paint37_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M525 1587L662 1507.48L525 1432.71L662 1357'
                stroke='url(#paint38_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M786 1587L923 1507.48L786 1432.71L923 1357'
                stroke='url(#paint39_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1045 1588L1182 1508.48L1045 1433.71L1182 1358'
                stroke='url(#paint40_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1571 1588L1708 1508.48L1571 1433.71L1708 1358'
                stroke='url(#paint41_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M-1 1587L136 1507.48L-1 1432.71L136 1357'
                stroke='url(#paint42_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1446.01 1816.4L1583.01 1736.87L1446.01 1662.11L1583.01 1586.4'
                stroke='url(#paint43_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M402.006 1815L539.006 1735.48L402.006 1660.71L539.006 1585'
                stroke='url(#paint44_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M663 1815.4L800 1735.87L663 1661.11L800 1585.4'
                stroke='url(#paint45_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M924 1815.4L1061 1735.87L924 1661.11L1061 1585.4'
                stroke='url(#paint46_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1183 1816.4L1320 1736.87L1183 1662.11L1320 1586.4'
                stroke='url(#paint47_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1709 1816.4L1846 1736.87L1709 1662.11L1846 1586.4'
                stroke='url(#paint48_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M137 1815.4L274 1735.87L137 1661.11L274 1585.4'
                stroke='url(#paint49_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1308 1286L1439 1207.51L1308 1133.73L1439 1059'
                stroke='url(#paint50_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1049 1287L1182 1207.48L1049 1132.71L1182 1057'
                stroke='url(#paint51_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M790 1286L919 1206.48L790 1131.71L919 1056'
                stroke='url(#paint52_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M527 1286L657 1206.82L527 1132.38L657 1057'
                stroke='url(#paint53_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M266 1286L397 1207.17L266 1133.06L397 1058'
                stroke='url(#paint54_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M6 1286L136 1207.17L6 1133.06L136 1058'
                stroke='url(#paint55_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1438 1061.98L1569.68 984.643L1439.33 909.716L1570.98 836.135'
                stroke='url(#paint56_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1178.99 1062.98L1312.68 984.615L1180.34 908.695L1313.99 834.145'
                stroke='url(#paint57_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M919.99 1061.99L1049.68 983.596L921.336 907.711L1050.99 833.126'
                stroke='url(#paint58_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M656.994 1061.99L787.68 983.949L658.334 908.38L788.987 834.132'
                stroke='url(#paint59_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M396 1061.98L527.683 984.298L397.334 909.045L528.984 835.135'
                stroke='url(#paint60_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M136 1061.99L266.683 984.295L137.334 909.051L267.984 835.132'
                stroke='url(#paint61_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1699 1059.99L1832.69 981.631L1700.35 905.711L1834 831.161'
                stroke='url(#paint62_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1702 1363.99L1835.69 1285.63L1703.35 1209.71L1837 1135.16'
                stroke='url(#paint63_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
              <path
                d='M1440 1362.99L1573.69 1284.63L1441.35 1208.71L1575 1134.16'
                stroke='url(#paint64_linear_528_3282)'
                stroke-width='2'
                className='wire'
              />
            </g>
            <defs>
              <linearGradient
                id='paint0_linear_528_3282'
                x1='1486.73'
                y1='544.703'
                x2='1566.76'
                y2='544.008'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint1_linear_528_3282'
                x1='442.735'
                y1='543.306'
                x2='522.758'
                y2='542.611'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint2_linear_528_3282'
                x1='703.729'
                y1='543.703'
                x2='783.753'
                y2='543.008'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint3_linear_528_3282'
                x1='964.729'
                y1='543.703'
                x2='1044.75'
                y2='543.008'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint4_linear_528_3282'
                x1='1223.73'
                y1='544.703'
                x2='1303.75'
                y2='544.008'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint5_linear_528_3282'
                x1='1749.73'
                y1='544.703'
                x2='1829.75'
                y2='544.008'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint6_linear_528_3282'
                x1='177.729'
                y1='543.703'
                x2='257.753'
                y2='543.008'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint7_linear_528_3282'
                x1='1484.64'
                y1='243.529'
                x2='1561.16'
                y2='242.884'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint8_linear_528_3282'
                x1='1747.64'
                y1='243.529'
                x2='1824.16'
                y2='242.884'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint9_linear_528_3282'
                x1='1615.64'
                y1='468.529'
                x2='1692.16'
                y2='467.884'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint10_linear_528_3282'
                x1='1226.34'
                y1='243.703'
                x2='1304.02'
                y2='243.048'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint11_linear_528_3282'
                x1='965.942'
                y1='242.703'
                x2='1041.29'
                y2='242.086'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint12_linear_528_3282'
                x1='703.29'
                y1='242.978'
                x2='779.226'
                y2='242.349'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint13_linear_528_3282'
                x1='442.639'
                y1='243.253'
                x2='519.158'
                y2='242.612'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint14_linear_528_3282'
                x1='182.29'
                y1='243.253'
                x2='258.226'
                y2='242.622'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint15_linear_528_3282'
                x1='1351.28'
                y1='1073.52'
                x2='1431.31'
                y2='1073.52'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint16_linear_528_3282'
                x1='307.284'
                y1='1072.12'
                x2='387.31'
                y2='1072.13'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint17_linear_528_3282'
                x1='568.278'
                y1='1072.52'
                x2='648.305'
                y2='1072.52'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint18_linear_528_3282'
                x1='829.278'
                y1='1072.52'
                x2='909.302'
                y2='1072.52'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint19_linear_528_3282'
                x1='1088.28'
                y1='1073.52'
                x2='1168.3'
                y2='1073.52'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint20_linear_528_3282'
                x1='1614.28'
                y1='1073.52'
                x2='1694.3'
                y2='1073.52'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint21_linear_528_3282'
                x1='42.2773'
                y1='1072.52'
                x2='122.304'
                y2='1072.52'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint22_linear_528_3282'
                x1='1349.2'
                y1='772.353'
                x2='1425.72'
                y2='772.375'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint23_linear_528_3282'
                x1='1090.89'
                y1='772.525'
                x2='1168.57'
                y2='772.548'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint24_linear_528_3282'
                x1='830.491'
                y1='771.528'
                x2='905.842'
                y2='771.569'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint25_linear_528_3282'
                x1='567.841'
                y1='771.804'
                x2='643.779'
                y2='771.838'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint26_linear_528_3282'
                x1='307.193'
                y1='772.077'
                x2='383.715'
                y2='772.103'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint27_linear_528_3282'
                x1='46.8442'
                y1='772.079'
                x2='122.783'
                y2='772.111'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint28_linear_528_3282'
                x1='1349.2'
                y1='772.353'
                x2='1425.72'
                y2='772.375'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint29_linear_528_3282'
                x1='1090.89'
                y1='772.525'
                x2='1168.57'
                y2='772.548'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint30_linear_528_3282'
                x1='830.491'
                y1='771.528'
                x2='905.842'
                y2='771.569'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint31_linear_528_3282'
                x1='567.841'
                y1='771.804'
                x2='643.779'
                y2='771.838'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint32_linear_528_3282'
                x1='307.193'
                y1='772.077'
                x2='383.715'
                y2='772.103'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint33_linear_528_3282'
                x1='46.8442'
                y1='772.079'
                x2='122.783'
                y2='772.111'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint34_linear_528_3282'
                x1='1614.89'
                y1='771.101'
                x2='1692.57'
                y2='771.124'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint35_linear_528_3282'
                x1='1746.89'
                y1='848.101'
                x2='1824.57'
                y2='848.124'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint36_linear_528_3282'
                x1='1355.73'
                y1='1524.7'
                x2='1435.76'
                y2='1524.01'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint37_linear_528_3282'
                x1='311.735'
                y1='1523.31'
                x2='391.758'
                y2='1522.61'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint38_linear_528_3282'
                x1='572.729'
                y1='1523.7'
                x2='652.753'
                y2='1523.01'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint39_linear_528_3282'
                x1='833.729'
                y1='1523.7'
                x2='913.75'
                y2='1523.01'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint40_linear_528_3282'
                x1='1092.73'
                y1='1524.7'
                x2='1172.75'
                y2='1524.01'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint41_linear_528_3282'
                x1='1618.73'
                y1='1524.7'
                x2='1698.75'
                y2='1524.01'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint42_linear_528_3282'
                x1='46.729'
                y1='1523.7'
                x2='126.753'
                y2='1523.01'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint43_linear_528_3282'
                x1='1493.73'
                y1='1753.1'
                x2='1573.76'
                y2='1752.4'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint44_linear_528_3282'
                x1='449.735'
                y1='1751.7'
                x2='529.758'
                y2='1751.01'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint45_linear_528_3282'
                x1='710.729'
                y1='1752.1'
                x2='790.753'
                y2='1751.4'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint46_linear_528_3282'
                x1='971.729'
                y1='1752.1'
                x2='1051.75'
                y2='1751.4'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint47_linear_528_3282'
                x1='1230.73'
                y1='1753.1'
                x2='1310.75'
                y2='1752.4'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint48_linear_528_3282'
                x1='1756.73'
                y1='1753.1'
                x2='1836.75'
                y2='1752.4'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint49_linear_528_3282'
                x1='184.729'
                y1='1752.1'
                x2='264.753'
                y2='1751.4'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint50_linear_528_3282'
                x1='1353.64'
                y1='1223.53'
                x2='1430.16'
                y2='1222.88'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint51_linear_528_3282'
                x1='1095.34'
                y1='1223.7'
                x2='1173.02'
                y2='1223.05'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint52_linear_528_3282'
                x1='834.942'
                y1='1222.7'
                x2='910.29'
                y2='1222.09'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint53_linear_528_3282'
                x1='572.29'
                y1='1222.98'
                x2='648.226'
                y2='1222.35'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint54_linear_528_3282'
                x1='311.639'
                y1='1223.25'
                x2='388.158'
                y2='1222.61'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint55_linear_528_3282'
                x1='51.29'
                y1='1223.25'
                x2='127.226'
                y2='1222.62'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint56_linear_528_3282'
                x1='1484.19'
                y1='999.913'
                x2='1560.71'
                y2='999.936'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint57_linear_528_3282'
                x1='1225.88'
                y1='1000.09'
                x2='1303.56'
                y2='1000.11'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint58_linear_528_3282'
                x1='965.483'
                y1='999.089'
                x2='1040.83'
                y2='999.129'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint59_linear_528_3282'
                x1='702.832'
                y1='999.365'
                x2='778.771'
                y2='999.399'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint60_linear_528_3282'
                x1='442.184'
                y1='999.637'
                x2='518.706'
                y2='999.664'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint61_linear_528_3282'
                x1='181.835'
                y1='999.64'
                x2='257.774'
                y2='999.672'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint62_linear_528_3282'
                x1='1745.89'
                y1='997.101'
                x2='1823.57'
                y2='997.124'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint63_linear_528_3282'
                x1='1748.89'
                y1='1301.1'
                x2='1826.57'
                y2='1301.12'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <linearGradient
                id='paint64_linear_528_3282'
                x1='1486.89'
                y1='1300.1'
                x2='1564.57'
                y2='1300.12'
                gradientUnits='userSpaceOnUse'
              >
                <stop stop-color='#EF35FF' stop-opacity='0.05' />
                <stop offset='0.654778' stop-color='#EF35FF' />
                <stop offset='1' stop-opacity='0' />
              </linearGradient>
              <clipPath id='clip0_528_3282'>
                <rect width='1920' height='1080' fill='white' />
              </clipPath>
              <clipPath id='clip1_528_3282'>
                <rect
                  width='1920'
                  height='1080'
                  fill='white'
                  transform='translate(1 905)'
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </section>
  );
};
