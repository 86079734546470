import { pages } from '../pages'
import { Route } from 'react-router-dom'
import { WithRoutes } from './providers/WithRoutes'
import { Layout } from './providers/Layout'
import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { mainnet, goerli } from 'wagmi/chains'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'

const chains = [mainnet, goerli]
const projectId = '93599da170bedc0633ef006a7d19aa25'
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})

const ethereumClient = new EthereumClient(wagmiConfig, chains)

const App = () => {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <WithRoutes>
          {pages.map((page) => (
            <Route
              key={page.path}
              path={page.path}
              element={<Layout>{page.element}</Layout>}
            ></Route>
          ))}
        </WithRoutes>
      </WagmiConfig>

      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeMode="dark"
        themeVariables={{
          '--w3m-background-color': '#996BFF',
          '--w3m-overlay-backdrop-filter': 'blur(5px)',
          '--w3m-overlay-background-color': 'rgba(101,31,255,0.1)',
          '--w3m-font-family': 'Questrial, sans-serif',
        }}
      />
    </>
  )
}

export default App
