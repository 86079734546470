import { Claim } from "./Claim/Claim";
import { Gitbook } from "./Gitbook/Gitbook";
import { Home } from "./Home/Home";
import { RevenueShare } from "./RevenueShare/RevenueShare";
import { Trading } from "./Trading/Trading";
import { Verify } from "./Verify/Verify";

export const pages = [
  {
    path: "/",
    element: <Home />,
    withFullpageScroll: true,
  },
  {
    path: "/revenue-share",
    element: <RevenueShare />,
    withFullpageScroll: true,
  },
  {
    path: "/gitbook",
    element: <Gitbook />,
    withFullpageScroll: true,
  },
  {
    path: "/claim",
    element: <Claim />,
    withFullpageScroll: true,
  },
  {
    path: "/trading",
    element: <Trading />,
    withFullpageScroll: true,
  },
  {
    path: "/verify",
    element: <Verify />,
    withFullpageScroll: true,
  },
];
