import clsx from "clsx";
import styles from "./Tokenomics.module.scss";
import { ITokenomic } from "../../../../interfaces/IData";
import { useEffect, useState } from "react";
import { tokenomicsService } from "../../../../app/services/Tokenomics.service";
import { getMediaUrl } from "../../../../utils";

// import { tokenomics } from '../../../../data'

export const Tokenomics = () => {
  const [title, setTitle] = useState<string>("Tokenomics");
  /* const [tokenomics, setTokenomics] = useState<ITokenomic[]>(); */
  const tokenomics = [
    {
      id: 1,
      value: "1000000",
      text: "Max Supply",
      img: {
        id: 2,
        name: "1.png",
        alternativeText: null,
        caption: null,
        width: 100,
        height: 100,
        formats: null,
        hash: "1_980b21f019",
        ext: ".png",
        mime: "image/png",
        size: 4.04,
        url: "/images/Features/1.png",
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        folderPath: "/",
        createdAt: "2023-08-15T11:52:46.529Z",
        updatedAt: "2023-08-15T11:52:46.529Z",
      },
    },
    {
      id: 2,
      value: "5%",
      text: "Buy/Sell Tax",
      img: {
        id: 3,
        name: "2.png",
        alternativeText: null,
        caption: null,
        width: 100,
        height: 100,
        formats: null,
        hash: "2_b34235dad6",
        ext: ".png",
        mime: "image/png",
        size: 2.33,
        url: "/images/Features/2.png",
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        folderPath: "/",
        createdAt: "2023-08-15T11:53:30.889Z",
        updatedAt: "2023-08-15T11:53:30.889Z",
      },
    },
    {
      id: 3,
      value: "100%",
      text: "Locked Liquidity",
      img: {
        id: 4,
        name: "3.png",
        alternativeText: null,
        caption: null,
        width: 100,
        height: 100,
        formats: null,
        hash: "3_87f90f3fe1",
        ext: ".png",
        mime: "image/png",
        size: 2.83,
        url: "/images/Features/3.png",
        previewUrl: null,
        provider: "local",
        provider_metadata: null,
        folderPath: "/",
        createdAt: "2023-08-15T11:53:59.443Z",
        updatedAt: "2023-08-15T11:53:59.443Z",
      },
    },
  ];

  /*  useEffect(() => {
    async function fetchData() {
      const response = await tokenomicsService.getAll();

      if (response) {
        setTokenomics(response[0].list);
        setTitle(response[0].title);
      }
    }

    fetchData();
  }, []); */

  return (
    <section id='tokenomics' className={clsx("section", styles.tokenomics)}>
      <div className='container'>
        <div className={styles.wrapper}>
          <div>
            <h2 className='reveal'>{title && title}</h2>
          </div>
          <div className={styles.tokenomics}>
            {tokenomics &&
              tokenomics.map((tokenomic, idx) => (
                <div
                  key={idx}
                  style={{
                    transitionDelay: `${idx * 0.125 + 0.5}s`,
                  }}
                  className={clsx(styles.tokenomic, "reveal")}
                >
                  <div className={styles.icon}>
                    <img src={tokenomic.img.url} alt='' />
                  </div>

                  <div className={styles.title}>
                    <h4>{tokenomic.value !== "1%" ? tokenomic.value : " "}</h4>
                  </div>

                  <div className={styles.subtitle}>
                    <span>{tokenomic.text}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};
