import { Features } from './Features/Features'
import { Hero } from './Hero/Hero'
import { Roadmap } from './Roadmap/Roadmap'
import { Tokenomics } from './Tokenomics/Tokenomics'

// export const HomeScreens = [<Hero />, <Features />, <Roadmap />]

export const HomeScreens = [<Hero />, <Features />, <Tokenomics />, <Roadmap />]

// export const HomeScreens = [<Hero />, <Roadmap />]
