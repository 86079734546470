import React, { useContext, useEffect } from 'react'

import { WithFullpage } from '../../app/providers/WithFullpage'
import { RevenueShareScreens } from '../../widgets/Screens/RevenueShare'
import { MainContext } from '../../app/providers/MainContext'

export const RevenueShare = () => {
  const { setCurrentPage } = useContext(MainContext)

  useEffect(() => {
    setCurrentPage('revenue-share')
  }, [])

  return (
    <>
      <WithFullpage
        fullpageOptions={{
          credits: {
            enabled: false,
          },
          scrollingSpeed: 1000,
          touchSensitivity: 10,
        }}
      >
        <>
          {RevenueShareScreens.map((screen, idx) => (
            <React.Fragment key={idx}>{screen}</React.Fragment>
          ))}
        </>
      </WithFullpage>
    </>
  )
}
