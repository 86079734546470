"use client";
import React, { createContext, useEffect, useState } from "react";
const { ethereum } = typeof window !== "undefined" ? window : {};
export const AppContext = createContext();

const requestSignature = async (account, message) => {
  try {
    const signature = await window.ethereum.request({
      method: "personal_sign",
      params: [message, account],
    });

    // Handle the signature as needed

    return signature;
  } catch (error) {
    console.log("Error requesting signature:", error);
  }
};

const AppProvider = ({ children }) => {
  const [account, setAccount] = useState("");
  const [error, setError] = useState("");

  const checkEthereumExists = () => {
    if (!ethereum) {
      setError("Please Install MetaMask.");
      return false;
    }
    return true;
  };

  const connectWallet = async () => {
    setError("");
    if (checkEthereumExists()) {
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const signature = await requestSignature(
          accounts[0],
          "Welcome to TwitTool! Signing is the only way we can know that you are the owner of the wallet you are connecting. Signing is a safe, gas-less transaction that does not in any way give TwitTool control of your account"
        );
        if (signature) {
          console.log(accounts);
          setAccount(accounts[0]);
        }
      } catch (err) {
        setError(err.message);
      }
    }
  };

  return (
    <AppContext.Provider value={{ account, connectWallet, error }}>
      {children}
    </AppContext.Provider>
  );
};
export default AppProvider;
