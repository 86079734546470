export function getRandomIntInclusive(min: number, max: number) {
  // Generate a random number between 0 and 1.
  let randomNumber = Math.random()

  // Scale the random number to the desired range.
  randomNumber = min + (max - min) * randomNumber

  // Return the random number.
  return randomNumber
}
