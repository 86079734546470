import { FC, ReactNode, useEffect, useState } from 'react'
import Header from '../../widgets/Header/Header'
import Footer from '../../widgets/Footer/Footer'

import { MainContext } from './MainContext'
import { Preloader } from '../../widgets/Preloader/Preloader'
import { useLocation, useParams } from 'react-router-dom'

interface IProps {
  children: ReactNode
}

export const Layout: FC<IProps> = ({ children }) => {
  const [pageLoaded, setPageLoaded] = useState<boolean>(false)
  const [activeScreen, setActiveScreen] = useState<number>(0)
  const [direction, setDirection] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<string>('')
  const [blockedScroll, setBlockedScroll] = useState<boolean>(false)

  return (
    <MainContext.Provider
      value={{
        activeScreen,
        setActiveScreen,
        direction,
        setDirection,
        pageLoaded,
        setPageLoaded,
        currentPage,
        setCurrentPage,
        blockedScroll,
        setBlockedScroll,
      }}
    >
      {/* <Preloader /> */}
      <Header />
      {children}
      {/* <Footer /> */}
    </MainContext.Provider>
  )
}
