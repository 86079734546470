import React, { useContext, useEffect, useState } from 'react'
import { HomeContext } from '../../app/providers/HomeContext'
import { HomeScreens } from '../../widgets/Screens/Home'
import { WithFullpage } from '../../app/providers/WithFullpage'
import { MainContext } from '../../app/providers/MainContext'

export const Home = () => {
  const {
    setActiveScreen,
    activeScreen,
    setDirection,
    setCurrentPage,
    blockedScroll,
  } = useContext(MainContext)

  const [activeSlideId, setActiveSlideId] = useState<number>(0)

  useEffect(() => {
    setCurrentPage('home')
  }, [])

  return (
    <HomeContext.Provider
      value={{
        activeSlideId,
        setActiveSlideId,
      }}
    >
      <WithFullpage
        fullpageOptions={{
          credits: {
            enabled: false,
          },
          scrollingSpeed: 1000,
          touchSensitivity: 5,

          fitToSection: false,
          fitToSectionDelay: 0,
          scrollBar: window.innerWidth <= 768 ? true : false,
          normalScrollElements:
            window.innerWidth <= 768 ? '#tokenomics, #roadmap' : '',
          onLeave: (origin, destination, direction, trigger) => {
            setActiveScreen(destination.index)
            setDirection(direction)
          },
        }}
      >
        <>
          {HomeScreens.map((screen, idx) => (
            <React.Fragment key={idx}>{screen}</React.Fragment>
          ))}
        </>
      </WithFullpage>
    </HomeContext.Provider>
  )
}
