import { ButtonHTMLAttributes, ComponentPropsWithoutRef, FC } from 'react'
import styles from './Button.module.scss'
import clsx from 'clsx'

interface IProps extends ComponentPropsWithoutRef<'button'> {
  colorType?: 'one' | 'two'
  icon?: string
}

export const Button: FC<IProps> = ({
  children,
  colorType = 'one',
  icon,
  className,
  ...props
}) => {
  return (
    <button
      className={clsx(styles.button, styles[colorType], className && className)}
      {...props}
    >
      <div className={styles.bg}></div>
      <div className={clsx(styles.bg, styles.bgTwo)}></div>

      {children}
      {icon && (
        <div className={styles.icon}>
          <img src={icon} alt="" />
        </div>
      )}
    </button>
  )
}
