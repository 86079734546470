import React, { useContext, useEffect } from 'react'

import { WithFullpage } from '../../app/providers/WithFullpage'
import { MainContext } from '../../app/providers/MainContext'
import { TradingScreens } from '../../widgets/Screens/Trading'

export const Trading = () => {
  const { setCurrentPage } = useContext(MainContext)

  useEffect(() => {
    setCurrentPage('trading')
  }, [])

  return (
    <>
      <WithFullpage
        fullpageOptions={{
          credits: {
            enabled: false,
          },
          scrollingSpeed: 1000,
          touchSensitivity: 10,
        }}
      >
        <>
          {TradingScreens.map((screen, idx) => (
            <React.Fragment key={idx}>{screen}</React.Fragment>
          ))}
        </>
      </WithFullpage>
    </>
  )
}
