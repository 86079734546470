import React, { useContext, useEffect } from "react";

import { WithFullpage } from "../../app/providers/WithFullpage";
import { MainContext } from "../../app/providers/MainContext";
import { VerifyScreens } from "../../widgets/Screens/Verify";
// @ts-ignore
import AppProvider from "../../context/AppContext.jsx";

export const Verify = () => {
  const { setCurrentPage } = useContext(MainContext);

  useEffect(() => {
    setCurrentPage("verify");
  }, []);

  return (
    <AppProvider>
      <WithFullpage
        fullpageOptions={{
          credits: {
            enabled: false,
          },
          scrollingSpeed: 1000,
          touchSensitivity: 10,
        }}
      >
        <>
          {VerifyScreens.map((screen, idx) => (
            <React.Fragment key={idx}>{screen}</React.Fragment>
          ))}
        </>
      </WithFullpage>
    </AppProvider>
  );
};
