import "./FeaturesSlider.scss";
import { Mousewheel, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { useEffect, useState, useContext } from "react";
// import { features } from '../../data'
import { HomeContext } from "../../app/providers/HomeContext";
import { MainContext } from "../../app/providers/MainContext";
import { Swiper as ISwiper } from "swiper";
import { IFeature } from "../../interfaces/IData";
import { featuresService } from "../../app/services/Features.service";
import { getMediaUrl } from "../../utils";
import clsx from "clsx";
const features = [
  {
    id: 8,
    title: "Free Access",
    text: "Manual Token Sniper, Anti Rug, Mev Protection, Private Transactions, Image Generated P&L Analysis, 1% Fees",
    isFree: true,
    img: {
      id: 21,
      name: "slide4.png",
      alternativeText: null,
      caption: null,
      width: 370,
      height: 427,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/images/Features/slide4.png",
          hash: "thumbnail_slide4_d41a54a831",
          mime: "image/png",
          name: "thumbnail_slide4.png",
          path: null,
          size: 22.59,
          width: 135,
          height: 156,
        },
      },
      hash: "slide4_d41a54a831",
      ext: ".png",
      mime: "image/png",
      size: 22.36,
      url: "/images/Features/slide4.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      folderPath: "/",
      createdAt: "2023-08-15T13:30:10.247Z",
      updatedAt: "2023-08-15T13:30:10.247Z",
    },
  },
  {
    id: 7,
    title: "1000 $PROPHET",
    text: "Manual Token Sniper, Anti Rug, Mev Protection Private Transactions, Image Generated P&L Analysis, ProphetBots AutoSniper, Multi-Wallet Trading, ProphetBots Algorithm Signals, Fresh Wallet Mode, Copy Trader, 1% Fees",
    isFree: false,
    img: {
      id: 22,
      name: "slide3.png",
      alternativeText: null,
      caption: null,
      width: 1084,
      height: 774,
      formats: {
        large: {
          ext: ".png",
          url: "/images/Features/slide3.png",
          hash: "large_slide3_e64f84b972",
          mime: "image/png",
          name: "large_slide3.png",
          path: null,
          size: 599.7,
          width: 1000,
          height: 714,
        },
        small: {
          ext: ".png",
          url: "/images/Features/slide1.png",
          hash: "small_slide3_e64f84b972",
          mime: "image/png",
          name: "small_slide3.png",
          path: null,
          size: 171.49,
          width: 500,
          height: 357,
        },
        medium: {
          ext: ".png",
          url: "/images/medium_slide3_e64f84b972.png",
          hash: "medium_slide3_e64f84b972",
          mime: "image/png",
          name: "medium_slide3.png",
          path: null,
          size: 358.5,
          width: 750,
          height: 536,
        },
        thumbnail: {
          ext: ".png",
          url: "/images/thumbnail_slide3_e64f84b972.png",
          hash: "thumbnail_slide3_e64f84b972",
          mime: "image/png",
          name: "thumbnail_slide3.png",
          path: null,
          size: 45.82,
          width: 218,
          height: 156,
        },
      },
      hash: "slide3_e64f84b972",
      ext: ".png",
      mime: "image/png",
      size: 122.17,
      url: "/images/Features/slide3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      folderPath: "/",
      createdAt: "2023-08-15T13:30:10.517Z",
      updatedAt: "2023-08-15T13:30:10.517Z",
    },
  },
  {
    id: 6,
    title: "2000 $PROPHET",
    text: "Manual Token Sniper, Anti Rug, Mev Protection Private Transactions, Image Generated P&L Analysis, ProphetBots AutoSniper, Multi-Wallet Trading, ProphetBots Algorithm Signals, Fresh Wallet Mode, Copy Trader, Big Brain Mode, Early Bird Mode, Revenue Share, 1% Fees",
    isFree: false,
    img: {
      id: 19,
      name: "slide2.png",
      alternativeText: null,
      caption: null,
      width: 344,
      height: 366,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/images/Features/slide2.png",
          hash: "thumbnail_slide2_1a0c659f6d",
          mime: "image/png",
          name: "thumbnail_slide2.png",
          path: null,
          size: 29.69,
          width: 147,
          height: 156,
        },
      },
      hash: "slide2_1a0c659f6d",
      ext: ".png",
      mime: "image/png",
      size: 22.68,
      url: "/images/Features/slide2.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      folderPath: "/",
      createdAt: "2023-08-15T13:30:10.030Z",
      updatedAt: "2023-08-15T13:30:10.030Z",
    },
  },

  {
    id: 5,
    title: "3000 $PROPHET",
    text: "Manual Token Sniper, Anti Rug, Mev Protection Private Transactions, Image Generated P&L Analysis, ProphetBots AutoSniper, Multi-Wallet Trading, ProphetBots Algorithm Signals, Fresh Wallet Mode, Copy Trader, Big Brain Mode, Early Bird Mode, God Mode, Revenue Share, 0.5% Fees",
    isFree: false,
    img: {
      id: 20,
      name: "slide1.png",
      alternativeText: null,
      caption: null,
      width: 614,
      height: 536,
      formats: {
        small: {
          ext: ".png",
          url: "/images/Features/slide1.png",
          hash: "small_slide1_c81b90bb64",
          mime: "image/png",
          name: "small_slide1.png",
          path: null,
          size: 92.53,
          width: 500,
          height: 436,
        },
        thumbnail: {
          ext: ".png",
          url: "/images/Features/slide1.png",
          hash: "thumbnail_slide1_c81b90bb64",
          mime: "image/png",
          name: "thumbnail_slide1.png",
          path: null,
          size: 18.82,
          width: 179,
          height: 156,
        },
      },
      hash: "slide1_c81b90bb64",
      ext: ".png",
      mime: "image/png",
      size: 26.3,
      url: "/images/Features/slide1.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      folderPath: "/",
      createdAt: "2023-08-15T13:30:10.144Z",
      updatedAt: "2023-08-15T13:30:10.144Z",
    },
  },
  {
    id: 4,
    title: "15000 $PROPHET",
    text: "Coming Soon",
    isFree: false,
    img: {
      id: 20,
      name: "slide1.png",
      alternativeText: null,
      caption: null,
      width: 614,
      height: 536,
      formats: {
        small: {
          ext: ".png",
          url: "/images/Hero/telegram.png",
          hash: "small_slide1_c81b90bb64",
          mime: "image/png",
          name: "small_slide1.png",
          path: null,
          size: 92.53,
          width: 500,
          height: 436,
        },
        thumbnail: {
          ext: ".png",
          url: "/images/Hero/telegram.png",
          hash: "thumbnail_slide1_c81b90bb64",
          mime: "image/png",
          name: "thumbnail_slide1.png",
          path: null,
          size: 18.82,
          width: 179,
          height: 156,
        },
      },
      hash: "slide1_c81b90bb64",
      ext: ".png",
      mime: "image/png",
      size: 26.3,
      url: "/images/Hero/telegram.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      folderPath: "/",
      createdAt: "2023-08-15T13:30:10.144Z",
      updatedAt: "2023-08-15T13:30:10.144Z",
    },
  },
];

export const FeaturesSlider = () => {
  /* const [features, setFeatures] = useState<IFeature[]>(); */

  useEffect(() => {
    /*  async function fetchData() {
      const response = await featuresService.getAll();

      if (response) {
        setFeatures(response[0].features);
      }
    } */
    /* fetchData(); */
  }, []);

  useEffect(() => features && setActiveSlide(features[0]), [features]);

  const { activeSlideId, setActiveSlideId } = useContext(HomeContext);
  // const { activeScreen, direction, setBlockedScroll, blockedScroll } =
  //   useContext(MainContext)

  const [activeSlide, setActiveSlide] = useState<IFeature | undefined>(
    undefined
  );

  // const [activeSlide, setActiveSlide] = useState<IFeature | undefined>(
  //   features[0]
  // )

  const [swiper, setSwiper] = useState<ISwiper>();

  useEffect(() => {
    if (features) {
      setActiveSlide(features[activeSlideId]);
    }
  }, [activeSlideId]);

  useEffect(() => {
    swiper?.slideTo(activeSlideId);
  }, [activeSlideId]);

  return (
    <>
      <div className='features-slider-wrapper'>
        <div className='light-scene'>
          <img
            className='reveal'
            src='/images/Features/light-scene.svg'
            alt=''
          />
        </div>

        <Swiper
          onSlideChange={(swiper) => {
            setActiveSlideId(swiper.activeIndex);
          }}
          slidesPerView={1}
          scrollbar={{
            hide: false,
          }}
          onSwiper={(swiper) => setSwiper(swiper)}
          modules={[Scrollbar]}
          direction='horizontal'
          // mousewheel={true}
          parallax={true}
          speed={1000}
          className='features-slider'
        >
          {features &&
            features.map((slide, idx) => (
              <SwiperSlide key={idx} className='features-slider__slide'>
                <div className='img reveal'>
                  <div className='wrapper safari-specific'>
                    <img src={slide.img.formats.thumbnail.url} alt='' />
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        <div className='bg-scene'>
          <img
            src={
              window.innerWidth > 768
                ? "/images/Features/bg-scene.svg"
                : "/images/Features/bg-scene-mobile.svg"
            }
            alt=''
          />
        </div>
        <div className='slide-info'>
          <div className='slide-arrows'>
            <div
              onClick={() => {
                swiper?.slidePrev();
              }}
              className={"arrow"}
            >
              <svg
                width='10'
                height='20'
                viewBox='0 0 10 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M9.06943 18.831C8.70252 19.1455 8.15013 19.103 7.83563 18.7361L0.835635 10.5694C0.554768 10.2418 0.554768 9.75824 0.835635 9.43056L7.83563 1.2639C8.15013 0.896984 8.70252 0.854492 9.06943 1.16899C9.43634 1.48348 9.47883 2.03587 9.16433 2.40278L2.65243 10L9.16433 17.5972C9.47883 17.9641 9.43634 18.5165 9.06943 18.831Z'
                  fill='white'
                />
              </svg>
            </div>

            <div onClick={() => swiper?.slideNext()} className={"arrow"}>
              <svg
                width='10'
                height='20'
                viewBox='0 0 10 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M0.930573 1.16898C1.29748 0.854485 1.84987 0.896976 2.16437 1.26389L9.16436 9.43055C9.44523 9.75823 9.44523 10.2418 9.16436 10.5694L2.16437 18.7361C1.84987 19.103 1.29748 19.1455 0.930573 18.831C0.563663 18.5165 0.521172 17.9641 0.835666 17.5972L7.34757 10L0.835666 2.40277C0.521172 2.03586 0.563663 1.48347 0.930573 1.16898Z'
                  fill='white'
                />
              </svg>
            </div>
          </div>
          <div className='slide-labels'>
            {features &&
              features.map((feature, idx) => (
                <span className={activeSlideId === idx ? "active" : ""}>
                  {feature.title}
                </span>
              ))}
          </div>
          <h2 className='reveal'>
            {activeSlide?.title}

            {!activeSlide?.isFree && (
              <img src='/images/Features/lock-title.svg' alt='' />
            )}
          </h2>
          <ul className='list'>
            {activeSlide?.text.split(", ").map((feature: any, idx: number) => (
              <li
                key={idx}
                style={{
                  transitionDelay: `${idx * 0.075 + 0.5}s`,
                }}
                className='reveal'
              >
                {feature}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};
