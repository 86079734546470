import clsx from "clsx";
import { useContext } from "react";
import styles from "./Features.module.scss";
import { FeaturesSlider } from "../../../../features/FeaturesSlider/FeaturesSlider";
import { tokenomics } from "../../../../data";
import { HomeContext } from "../../../../app/providers/HomeContext";
import { MainContext } from "../../../../app/providers/MainContext";

export const Features = () => {
  const { direction, setDirection } = useContext(MainContext);

  const { blockedScroll, activeSlideId, setBlockedScroll, setActiveSlideId } =
    useContext(HomeContext);

  return (
    <section id='features' className={clsx("section", styles.features)}>
      <div className='container'>
        <FeaturesSlider />
      </div>
    </section>
  );
};
