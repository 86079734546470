import { FC } from 'react'
import styles from './Logo.module.scss'

interface IProps {
  logoType?: 'common' | 'footer'
}

export const Logo: FC<IProps> = ({ logoType = 'common' }) => {
  return (
    <div className={styles.logo}>
      {logoType === 'common' ? (
        <img src="/images/logo.svg" alt="" />
      ) : (
        <img src="/images/logo-footer.svg" alt="" />
      )}
    </div>
  )
}
